<template>
  <div>
      <div class="indexContainer" v-if="$route.path === '/RedBag'">
      <div v-wechat-title="$route.meta.title"></div>
            <div class="greey"></div>
            <div class="Header">
                <div class="headerTop">
                    <img :src="`${userAward ? userAward : defaultAavnt}`" class="userAward">
                    <div class="user_name">{{user_name}}</div>
                </div>
                <div class="line"></div>
                <div class="headerMain">
                    <div class="extractable" @click="$router.push(`/RedBag/Withdraw?userId=${userId}&token=${token}&activity_id=${activity_id}`)">
                        <div class="extractableText">可提取</div>
                        <div class="extractableCount">{{extractableMony}}元</div>
                    </div>
                    <div class="unextractable"  @click="showPopup(1)">
                        <div class="unextractableGroup">
                            <div class="unextractableText">不可提取</div>
                            <img src="../img/问号 圆 疑问 面性.png" class="unextractableImg">
                        </div>
                        <div class="unextractableCount">{{unextractableMony}}元</div>
                    </div>
                </div>
                <div class="extract" @click="$router.push(`/RedBag/Withdraw?userId=${userId}&token=${token}&activity_id=${activity_id}`)" >提取收益</div>
                <div class="desc">30天内红包收益没有变动自动清零</div>
            </div>
            <div class="greey"></div>
            <div class="redBagList">
                <div class="redBagListHeader">
                    <div class="redBagListHeaderLeft">累计提取  {{totleCount}}元</div>
                    <div class="redBagListHeaderRight" @click="$router.push(`/RedBag/Rule`)">提取规则</div>
                </div>
                <div class="redBagListMain">
                    <div class="redBagItem" v-for="(item,Redbag) in redBagArr" :key="Redbag" v-if="redBagArr.length != 0">
                        <img src="../img/noExtract@2x.png" class="noextract" @click="showPopup(1)" v-show="item.frozen_status == 1">
                        <div class="redBagItemLeft">
                            <div class="redBagTitle" v-if="item.status == 3">提取失败(钱已返回账户)</div>
                            <div class="redBagTitle" v-else-if="item.status == 1">提取中</div>
                            <div class="redBagTitle" v-else-if="item.status == 2">提取成功</div>
                            <div class="redBagTitle" v-else>{{item.activity_name}}</div>
                            <div class="redBagTime">{{item.ctime}}</div>
                        </div>
                        <div class="redBagItemRight" v-if="item.status == '0'">+{{item.amount}}元</div>
                        <div class="redBagItemRight" v-else-if="item.status == '1'">{{item.amount}}元</div>
                        <div class="redBagItemRight" v-else-if="item.status == '2'" :style="{'color':'#02B31F'}">{{item.amount}}元</div>
                        <div class="checkDetails" @click="showPopup(2)" v-else-if="item.status == '3'">查看详情</div>
                        
                    </div>
                    <!-- <div class="redBagItem">
                        <img src="../img/noExtract@2x.png" class="noextract" @click="showPopup(1)">
                        
                        <div class="redBagItemLeft">
                            <div class="redBagTitle">{{redBagTitle}}</div>
                            <div class="redBagTime">{{redBagTime}}</div>
                        </div>
                        <div class="redBagItemRight">{{money}}</div>
                    </div>
                    <div class="redBagItem">
                        <img src="" class="noextract">
                        <div class="redBagItemLeft">
                            <div class="redBagTitle">{{redBagTitle}}</div>
                            <div class="redBagTime">{{redBagTime}}</div>
                        </div>
                        <div class="checkDetails" @click="showPopup(2)">查看详情</div>
                        <van-popup v-model="show1" overlay lock-scroll >
                            <div class="alertContainer">
                                <div class="alertTitle">红包收益为什么提取失败？</div>
                                <div class="alertText">
                                    1、账户未成功绑定第三方支付平台账号；
                                </div>
                                <div class="alertText">
                                    2、没有足够的收益来提取；
                                </div>
                                <div class="alertText">
                                    3、如果您确定您的账户已完成绑定且账户有足够的提现收益，那么提取失败的金额会在24小时内退回到你的账户，退回账户的金额可再次发起提取；
                                </div>
                                <div class="know" @click="close(2)">我知道了</div>
                            </div>
                        </van-popup>
                    </div> -->
                    <!-- <div class="noInfo" v-else>没有获得任何红包</div>
                    <div class="redBagDesc">入账红包将于30天后过期，请及时提取</div> -->
                </div>
                <van-popup v-model="show1" overlay lock-scroll >
                    <div class="alertContainer">
                        <div class="alertTitle">红包收益为什么提取失败？</div>
                        <div class="alertText">
                            1、账户未成功绑定第三方支付平台账号；
                        </div>
                        <div class="alertText">
                            2、没有足够的收益来提取；
                        </div>
                        <div class="alertText">
                            3、如果您确定您的账户已完成绑定且账户有足够的提现收益，那么提取失败的金额会在24小时内退回到你的账户，退回账户的金额可再次发起提取；
                        </div>
                        <div class="know" @click="close(2)">我知道了</div>
                    </div>
                </van-popup>
                <van-popup v-model="show" overlay lock-scroll >
                    <div class="alertContainer">
                        <div class="alertTitle">红包收益为什么不可提取？</div>
                        <div class="alertText">
                            不可提取的红包金额是部分云美摄活动参加后获取的红包金额，提取红包收益需要到指定的活动页面进行提取。
                        </div>
                        <div class="alertTitle">不可提取金额为什么没有了？</div>
                        <div class="alertText">
                            您参加的活动（该红包对应的）已结束，活动结束后未提现金额的将自动清除。
                        </div>
                        <div class="know" @click="close(1)">我知道了</div>
                    </div>
                </van-popup>
            </div>
        </div>
    <div v-else>
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Popup } from 'vant';

Vue.use(Popup);
export default{
    name: "Redbag",
    data(){
        return{
            user_name:'',
            extractableMony:0,
            unextractableMony:0,
            totleCount:0,
            redBagTitle:'分享页加油红包',
            redBagTime:'2020-06-10 10:10:10',
            money:'+1.22元',
            show:false,
            show1:false,
            userId:'',
            token:'',
            //列表数组
            redBagArr:[],
            userAward:"",
            app_key:'aki$G9$w0BA*QE',
            secret_key:"MICdgIBgkqhkiG9w0QEFoGBALXAwl29R7n2d",
            date: String(new Date().valueOf()).slice(0, 10),
            isList:false,
            //活动ID
            activity_id:0,
            defaultAavnt:require('../img/Group3@2x.png')
        }
    },
    async mounted(){
         //获取userId、token、activity_id
        this.userId = this.getRequest().userId ? this.getRequest().userId : "";
        this.token = this.getRequest().token ? this.getRequest().token : "";
        this.activity_id = this.getRequest().activity_id ? this.getRequest().activity_id : "";

        let sign = this.$md5(this.getSign(this.app_key,this.date,"RedPacket/getList",this.secret_key));
        //红包信息
        let {data,errNo,message} = await this.$api.getRedBagInfo(this.app_key,this.date,sign,this.token,this.userId)
        if(errNo == 0){
            this.extractableMony = data.balance.amount
            this.totleCount = data.balance.withdraw_amount
            this.unextractableMony = data.balance.frozen_amount
            this.redBagArr = data.list
            this.user_name = data.user_info.user_name
            this.userAward = data.user_info.profile_photo_url
        }
        
    },
    methods:{
        showPopup(num) {
            if(num == 1){
                this.show = true;
            }else if(num == 2){
                this.show1 = true
            }
            
        },
        close(num){
             if(num == 1){
                this.show = false;
            }else if(num == 2){
                this.show1 = false
            }
        },
         //格式化URL
        getRequest: () => {
            var url = window.location.search; //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = decodeURI(
                        strs[i].split("=")[1]
                    );
                }
            }

            return theRequest;
        },
        //获取签名
        getSign: (app_key, date, URL, secret_key) => {
            var sign ="app_key=" +app_key +"&" +"timestamp=" +date +"&" +"uri=" + URL +"&" +"secret_key=" +secret_key;
            return sign;
        },
    },
}
</script>
<style scoped>
/deep/.van-popup{
    border-radius: 4px;
}
.greey{
    background-color: #EDEDED;
    width: 100vw;
    height: 8px;
}
.Header{
    width: 100%;
    height: 260px;
    padding: 0 25px;
    box-sizing: border-box;
}
.headerTop{
    margin: 16px 0 0 19px;
    display: flex;
    align-items: center;
}
.userAward{
    width: 46px;
    height: 46px;
    border-radius: 50%;
}
.user_name{
    width: 100px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #323232;
    line-height: 22px;
    margin-left: 13px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space:nowrap;
}
.line{
    width: 325px;
    height: 1px;
    background: #F2F2F2;
    margin: 12px auto 17px;
}
.headerMain{
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
}
.extractableText{
    width: 43px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #919191;
    line-height: 20px;
}
.extractableCount{
    width: 140px;
    height: 40px;
    font-size: 28px;
    font-family: DINOT-Bold, DINOT;
    font-weight: bold;
    color: #323232;
    line-height: 36px;
}
.unextractable{
    margin-left: 29px;
}
.unextractableGroup{
    height: 20px;
    display: flex;
    align-items: center;
}
.unextractableText{
    width: 60px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #919191;
    line-height: 20px;

}
.unextractableCount{
    width: 125px;
    height: 40px;
    font-size: 28px;
    font-family: DINOT-Bold, DINOT;
    font-weight: bold;
    color: #323232;
    line-height: 36px;

}
.unextractableImg{
    width: 13px;
    height: 13px;
}
.extract{
    width: 276px;
    height: 46px;
    background: #E86258;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 46px;
    text-align: center;
    margin: 14px auto 0;
}
.desc{
    width: 250px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #919191;
    line-height: 17px;
    margin: 9px auto 22px;
    text-align: center;
}
.redBagListHeader{
    width: 375px;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    padding: 0 27px;
    box-sizing: border-box;
}
.redBagListHeaderLeft{
    width: 143px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #323232;
}
.redBagListHeaderRight{
    width: 58px;
    height: 17px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0D0D0D;
    margin-left: 122px;
}
.redBagListMain{
    padding: 0 27px;
}
.redBagItem{
    height: 69px;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F2F2;
    position: relative;
}
.redBagTitle{
    width: 171px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #191D21;
    line-height: 20px;
}
.redBagTime{
    width: 122px;
    height: 15px;
    font-size: 12px;
    font-family: DIN-Regular, DIN;
    font-weight: 400;
    color: #919191;
}
.redBagItemRight{
    width: 93px;
    height: 22px;
    font-size: 16px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #FE3256;
    line-height: 19px;
    margin-left: 58px;
    text-align: end;
}
.checkDetails{
    width: 68px;
    height: 24px;
    border-radius: 1px;
    border: 1px solid #FE3256;
    font-size: 12px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #FE3256;
    line-height: 24px;
    text-align: center;
    margin-left: 77px;

}
.noInfo{
    width: 114px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #191D21;
    line-height: 20px;
    margin: 104px auto 155px;
}
.redBagDesc{
    width: 210px;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #919191;
    margin: 43px auto 78px;
}
.noextract{
    width: 67px;
    height: 15px;
    position: absolute;
    top: 19px;
    left: 124px;
}
.alertContainer{
    padding: 26px 21px 0px;
}
.alertTitle{
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    margin-top: 10px;
}
.alertText{
    width: 293px;
    font-size: 14px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #666666;
    line-height: 26px;
    margin-top: 6px;
}
.know{
    width: 192px;
    height: 46px;
    background: #E86258;
    border-radius: 4px;
    font-size: 17px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 46px;
    text-align: center;
    margin: 24px auto;
}
</style>